import { CustomSize } from '@/common/types';
import { downloadFile } from '@/utils/file/pdf';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

const DisplayPdfFile = (props: { src: string; name: string; size: CustomSize }) => {
  const { src, name, size } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useScreenInfos();

  const handleDownload = () => {
    downloadFile(src, name)
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error('Error downloading PDF:', error);
      });
  };

  return (
    <Box>
      <Box>
        <Image
          src='/defaults/pdf.svg'
          alt={name}
          h='100%'
          mx='auto'
          cursor='pointer'
          onClick={onOpen}
          objectFit='cover'
          height={size.height}
          style={{ display: 'block', cursor: 'pointer' }}
        />
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size='4xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{name}</ModalHeader>
          <ModalCloseButton bg='neutral.100' zIndex='100' />
          <ModalBody>
            {isDesktop && <iframe title={name} src={src} width='100%' height='500px' />}
            {isMobile && (
              <Button colorScheme='gray' marginY={10} width={'100%'} onClick={handleDownload}>
                {t('download')}
              </Button>
            )}
          </ModalBody>
          <ModalFooter>
            {isDesktop && (
              <Button colorScheme='gray' mr={3} onClick={handleDownload}>
                {t('download')}
              </Button>
            )}
            <Button colorScheme='gray' mr={3} onClick={onClose}>
              {t('actions.close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DisplayPdfFile;
