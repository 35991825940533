import { useEffect, useRef, useState } from 'react';

export const useObserveElementWidth = <T extends HTMLElement>() => {
  const [observeWidth, setObserveWidth] = useState(0);
  const observeRef = useRef<T>(null);

  useEffect(() => {
    let observerRefValue: null | HTMLElement = null;

    const observer = new ResizeObserver((entries) => {
      setObserveWidth(entries[0].contentRect.width);
    });

    if (observeRef.current) {
      observer.observe(observeRef.current);
      observerRefValue = observeRef.current;
    }

    return () => {
      observerRefValue && observer.unobserve(observerRefValue);
    };
  }, []);

  return {
    observeWidth,
    observeRef,
  };
};
