import { Swiper as SwiperClass } from 'swiper';
import { Mousewheel, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper as SwiperWrapper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/scrollbar';
import { CustomSize } from '@/common/types';
import { useObserveElementWidth } from '@/utils/hooks/useObserveElementWidth';
import { Box } from '@chakra-ui/react';
import { FC, ReactNode, useMemo } from 'react';
import { SwiperModule } from 'swiper/types';

type SwiperProps = {
  slidesPerView?: number;
  spaceBetween?: number | string;
  breakPoints?: Record<number, object>;
  slideSize?: CustomSize;
  modules?: [string];
  children: ReactNode;
};

const PAGINATION_CONFIG = { clickable: true, dynamicBullets: true };
const SCROLLBAR_CONFIG = { hide: false, draggable: true };

const PAGINATION_MODULE = 'Pagination';
const MOUSEWHEEL_MODULE = 'Mousewheel';
export const SCROLLBAR_MODULE = 'Scrollbar';

const DEFAULT_MODULES = [PAGINATION_MODULE, MOUSEWHEEL_MODULE];
const DEFAULT_SLIDES_PER_VIEW = 1;
const DEFAULT_SPACE_BETWEEN = 10;
const DEFAULT_BREAKPOINTS = {
  768: {
    spaceBetween: 20,
  },
};

const modulesMap: Record<string, SwiperModule> = {
  [SCROLLBAR_MODULE]: Scrollbar,
  [PAGINATION_MODULE]: Pagination,
  [MOUSEWHEEL_MODULE]: Mousewheel,
};

const Swiper: FC<SwiperProps> = (props: SwiperProps) => {
  const { observeWidth, observeRef } = useObserveElementWidth<HTMLDivElement>();

  const {
    slidesPerView = DEFAULT_SLIDES_PER_VIEW,
    spaceBetween = DEFAULT_SPACE_BETWEEN,
    breakPoints = DEFAULT_BREAKPOINTS,
    slideSize,
    children,
    modules = DEFAULT_MODULES,
  } = props;

  const observeSlidesPerView = useMemo(
    () => slideSize && +(observeWidth / slideSize.width).toFixed(1),
    [observeWidth, slideSize]
  );

  const handlePaginationUpdate = (swiper: SwiperClass) => {
    const { pagination, mousewheel } = swiper;

    if (!mousewheel || !pagination) return;

    const isMouseWheelEnabled = pagination.bullets?.length > 1;

    isMouseWheelEnabled ? mousewheel.enable() : mousewheel.disable();
  };

  const swiperConfigs: Record<string, object> = {};

  if (modules.includes(PAGINATION_MODULE)) {
    swiperConfigs['pagination'] = PAGINATION_CONFIG;
    swiperConfigs['onPaginationUpdate'] = handlePaginationUpdate;
  }

  if (modules.includes(SCROLLBAR_MODULE)) {
    swiperConfigs['scrollbar'] = SCROLLBAR_CONFIG;
  }

  return (
    <Box ref={observeRef} overflow='hidden' userSelect='none'>
      <SwiperWrapper
        modules={[...modules.map((module) => modulesMap[module])]}
        slidesPerView={observeSlidesPerView || slidesPerView}
        spaceBetween={spaceBetween}
        breakpoints={breakPoints}
        {...swiperConfigs}
      >
        {children}
      </SwiperWrapper>
    </Box>
  );
};
export default Swiper;
