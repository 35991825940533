export const downloadFile = async (src: string, filename: string) => {
  const response = await fetch(src);
  const blob = await response.blob();
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = filename;
  downloadLink.click();

  window.URL.revokeObjectURL(downloadLink.href);
};
