import { Box } from '@chakra-ui/react';

import { CustomSize } from '@/common/types';
import { FC, useEffect, useRef, useState } from 'react';

export type DisplayVideoProps = {
  src: string;
  size?: CustomSize;
};

const DisplayVideo: FC<DisplayVideoProps> = (props: DisplayVideoProps) => {
  const { src, size } = props;
  const [isFullscreen, setIsFullscreen] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement === videoRef.current);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    /* Chrome, Safari and Opera */
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <Box
      ref={videoRef}
      as='video'
      src={src}
      controls
      maxWidth='100%'
      maxHeight='420px'
      w={size?.width ?? '100%'}
      h={size?.height ?? 'fit-content'}
      style={{ display: 'block' }}
      objectFit={isFullscreen ? 'contain' : 'cover'}
    />
  );
};

export default DisplayVideo;
