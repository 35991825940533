import { CustomSize } from '@/common/types';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import {
  Box,
  Button,
  CloseButton,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { MdDownload } from 'react-icons/md';

import { downloadFile } from '@/utils/file/pdf';
import useTranslation from '@/utils/i18n/useTranslation';
import { FC, lazy, useCallback, useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import CrossOriginImage from './CrossOriginImage';
import { type Editor } from './ImageEditor';
import { SuspenseWithSpinner } from './SuspenseWithSpinner';
const ImageEditor = lazy(() => import('./ImageEditor'));

export type DisplayImageProps = {
  src: string;
  name: string;
  size: CustomSize;
  showEditInModal?: boolean;
  onSaveEditedImage?: (dataUri: string, contentType: string, name: string) => Promise<void>;
};

const DisplayImage: FC<DisplayImageProps> = (props: DisplayImageProps) => {
  const { src, name, size, showEditInModal = false, onSaveEditedImage } = props;
  const { t } = useTranslation();
  const [mode, setMode] = useState<'viewer' | 'editor'>('viewer');
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => setMode('viewer'),
  });

  const editor = useRef<Editor | null>(null);
  const [isSavingEditedImage, setIsSavingEditedImage] = useState(false);

  const { isMobile } = useScreenInfos();

  const onEditorReady = useCallback((e: Editor) => {
    editor.current = e;
  }, []);

  return (
    <Box>
      <Image
        as={CrossOriginImage}
        src={src}
        alt={name}
        maxWidth='100%'
        maxHeight='420px'
        w={size.width}
        h={size.height}
        objectFit='cover'
        crossOrigin='anonymous'
        style={{ display: 'block', cursor: 'pointer' }}
        onClick={onOpen}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={mode === 'viewer'}
        size={{ base: 'full', md: '6xl' }}
        isCentered
        allowPinchZoom
      >
        <ModalOverlay />
        <ModalContent bg='neutral.700'>
          <ModalHeader display='flex' justifyContent='right' alignItems='center' gap={2}>
            <IconButton
              variant='outline'
              colorScheme='primary'
              size='sm'
              aria-label={t('download')}
              onClick={() => downloadFile(src, name)}
              icon={<MdDownload />}
            />
            {showEditInModal &&
              (mode === 'viewer' ? (
                <>
                  <Button
                    colorScheme='primary'
                    variant='outline'
                    size='sm'
                    onClick={() => setMode('editor')}
                  >
                    {t('actions.edit')}
                  </Button>
                </>
              ) : (
                <Button
                  colorScheme='primary'
                  size='sm'
                  disabled={isSavingEditedImage}
                  onClick={async () => {
                    setIsSavingEditedImage(true);
                    if (editor.current) {
                      const data = editor.current.export(name);
                      await onSaveEditedImage?.(data.dataUri, data.contentType, data.name);
                    }
                    setMode('viewer');
                    setIsSavingEditedImage(false);
                  }}
                >
                  {isSavingEditedImage ? <Spinner /> : t('actions.save')}
                </Button>
              ))}
            <CloseButton bg='neutral.400' zIndex='100' onClick={onClose} />
          </ModalHeader>
          <ModalBody display='flex' p={0} justifyContent='right' alignItems='center'>
            {mode === 'viewer' ? (
              <TransformWrapper initialScale={1} centerOnInit={true} centerZoomedOut={true}>
                <TransformComponent
                  wrapperStyle={{ width: '100%', height: isMobile ? '90svh' : '80vh' }}
                  contentStyle={{
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Image
                    as={CrossOriginImage}
                    crossOrigin='anonymous'
                    src={src}
                    alt={name}
                    objectFit='contain'
                    onClick={() => window.open(src)}
                  />
                </TransformComponent>
              </TransformWrapper>
            ) : (
              <Box w='100%' h={isMobile ? '90svh' : '80vh'}>
                <SuspenseWithSpinner>
                  <ImageEditor src={src} onEditorReady={onEditorReady} />
                </SuspenseWithSpinner>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DisplayImage;
