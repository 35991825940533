import { PropsOf, forwardRef } from '@chakra-ui/react';

type Props = {
  /**
   * The native HTML `width` attribute to the passed to the `img`
   */
  htmlWidth?: string | number;
  /**
   * The native HTML `height` attribute to the passed to the `img`
   */
  htmlHeight?: string | number;
};

const CrossOriginImage = forwardRef(function (
  props: PropsOf<'img'> & Props,
  ref: React.Ref<HTMLImageElement>
) {
  const { htmlWidth, htmlHeight, crossOrigin, alt, ...rest } = props;

  // chakra.imageだとsrcの後にcrossOriginが指定されてsafariだと2回ロードされてしまうので修正
  // see https://m2x-talk.slack.com/archives/C04083L7Z16/p1718623660905689
  // このファイル自体はほぼ　https://github.com/chakra-ui/chakra-ui/blob/v2/packages/components/src/image/native-image.tsx のコピー
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      width={htmlWidth}
      height={htmlHeight}
      ref={ref}
      crossOrigin={crossOrigin}
      alt={alt}
      {...rest}
    />
  );
});

export default CrossOriginImage;
